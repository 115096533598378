import { datadogRum } from '@datadog/browser-rum';
import { useLocation, useNavigate } from '@remix-run/react';
import type { User } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../plugin/firebase';

type UserType = User | null;

export type AuthContextProps = {
  user: UserType; // userはログインしているかどうかの判定に使う
  isAvailableForNoLoginUser: boolean;
};

export type AuthProps = {
  children: ReactNode;
};

const AVAILABLE_ROUTES_FOR_UNSIGNED_USER = ['/login', '/[locale]/login'];

const AuthContext = createContext<Partial<AuthContextProps>>({});

export const useAuthContext = () => useContext(AuthContext);

export async function getCurrentUser() {
  if (auth.currentUser) {
    return auth.currentUser;
  }
  return await new Promise<User>((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
        unsubscribe();
      }
    });
  });
}

export const AuthProvider = ({ children }: AuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<UserType>(null);

  const isAvailableForNoLoginUser = AVAILABLE_ROUTES_FOR_UNSIGNED_USER.includes(location.pathname);

  const value = {
    user,
    isAvailableForNoLoginUser,
  };

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, (user) => {
      setUser(user);

      datadogRum.addAction('AuthProvider_login', {
        route: location.pathname,
        isAvailableForNoLoginUser: isAvailableForNoLoginUser,
        userId: user?.uid,
      });

      if (!user && !isAvailableForNoLoginUser) {
        datadogRum.addAction('AuthProvider_logout', {
          route: location.pathname,
          isAvailableForNoLoginUser: isAvailableForNoLoginUser,
        });

        navigate(
          `/login?${new URLSearchParams({ redirect: location.pathname + location.search }).toString()}`,
          { replace: true }
        );
      }
    });

    return () => {
      authStateChanged();
    };
  }, [isAvailableForNoLoginUser, navigate, location]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
